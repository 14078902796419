<template>
  <v-row>
    <v-col md="12" class="gray-background-no">
      <table class="width-100">
        <tr>
          <td
            style="width: 100px"
            class="font-size-18 text-capitalize font-weight-500"
          >
            Full name
          </td>
          <td class="pb-2 font-size-18 text-capitalize">
            <!--  {{ engineer.emergency_contact_name || " no name " }} -->
            <template v-if="engineer.emergency_contact_name">{{
              engineer.emergency_contact_name
            }}</template>
            <template v-else><em class="text-muted">no name</em></template>
          </td>
        </tr>
        <tr>
          <td class="font-size-18 text-capitalize font-weight-500">
            Mobile No
          </td>
          <td class="font-size-18 text-capitalize">
            <!--  {{
                engineer.emergency_contact_number || " no mobile number "
              }} -->
            <template v-if="engineer.emergency_contact_number">{{
              engineer.emergency_contact_number
            }}</template>
            <template v-else
              ><em class="text-muted">no mobile number</em></template
            >
          </td>
        </tr>
        <tr>
          <td class="font-size-18 text-capitalize font-weight-500">Relation</td>
          <td class="font-size-18 text-capitalize">
            <!-- {{ engineer.emergency_contact_relation || " no relation " }} -->

            <template v-if="engineer.emergency_contact_relation">{{
              engineer.emergency_contact_relation
            }}</template>
            <template v-else><em class="text-muted">no relation</em></template>
          </td>
        </tr>
        <tr>
          <td class="font-size-18 text-capitalize font-weight-500">Email</td>
          <td class="font-size-18">
            <!-- {{ engineer.emergency_contact_email || " no email " }} -->

            <template v-if="engineer.emergency_contact_email">{{
              engineer.emergency_contact_email
            }}</template>
            <template v-else><em class="text-muted">no email</em></template>
          </td>
        </tr>
        <tr>
          <td class="font-size-18 text-capitalize font-weight-500">Address</td>
          <td class="font-size-18 text-capitalize">
            <template
              v-if="
                engineer.emergency_contact_unit_no ||
                engineer.emergency_contact_street_1 ||
                engineer.emergency_contact_street_2 ||
                engineer.emergency_contact_postal_code
              "
            >
              {{ getAddress }}
            </template>
            <template v-else><em class="text-muted">no address</em></template>

            <!-- {{ engineer.emergency_contact_unit_no }},
              {{ engineer.emergency_contact_street_1 }},
              {{ engineer.emergency_contact_street_2 }},
              {{ engineer.emergency_contact_postal_code }}. -->
          </td>
        </tr>
        <tr>
          <td class="font-size-18 text-capitalize font-weight-500">Country</td>
          <td class="font-size-18 text-capitalize">
            <template v-if="engineer.emergency_country_name"
              >{{ engineer.emergency_country_name }}
            </template>
            <template v-else><em class="text-muted">no country</em></template>
          </td>
        </tr>
      </table>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  name: "emergency-contact",
  mixins: [CommonMixin],
  props: {
    engineer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      fieldDescriptions: [],
    };
  },
  computed: {
    getAddress() {
      let address = [];
      const engineer = this.engineer;
      if (engineer.emergency_contact_unit_no) {
        address.push(engineer.emergency_contact_unit_no);
      }
      if (engineer.emergency_contact_street_1) {
        address.push(engineer.emergency_contact_street_1);
      }
      if (engineer.emergency_contact_street_2) {
        address.push(engineer.emergency_contact_street_2);
      }
      if (engineer.emergency_contact_postal_code) {
        address.push(engineer.emergency_contact_postal_code);
      }
      return address.join(", ");
    },
  },
};
</script>
