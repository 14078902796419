<template>
  <v-container fluid>
    <v-form
      ref="passwordForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit"
    >
      <v-row justify="center">
        <v-col md="2" class="pb-2">
          <label class="font-weight-bold">Laves Type</label>
        </v-col>
        <v-col md="2" class="pb-2">
          <label class="font-weight-bold"> Max Day</label>
        </v-col>
        <v-col md="2" class="pb-2">
          <label class="font-weight-bold"> Entitle </label>
        </v-col>
        <v-col md="2" class="pb-2">
          <label class="font-weight-bold"> Taken</label>
        </v-col>
        <v-col md="2" class="pb-2">
          <label class="font-weight-bold"> Balance </label>
        </v-col>
      </v-row>
      <v-row v-for="(type, index) in leaveList" :key="index" justify="center">
        <v-col md="2" class="pb-2">
          <v-text-field
            v-model.trim="type.category"
            dense
            filled
            label="Leave Type"
            solo
            flat
            readonly
            hide-details
            color="cyan"
          ></v-text-field>
        </v-col>
        <v-col md="2" class="pb-2">
          <v-text-field
            v-model.trim="type.maxdays"
            dense
            filled
            label="Max Days"
            solo
            flat
            readonly
            type="number"
            min="0"
            max="999"
            hide-details
            color="cyan"
          ></v-text-field>
        </v-col>
        <v-col md="2" class="pb-2">
          <v-text-field
            dense
            filled
            label="Allocated Leave"
            v-model.trim="type.allocated_days"
            solo
            flat
            type="number"
            min="0"
            v-on:keyup="addminvalue(type)"
            max="999"
            color="cyan"
          ></v-text-field>
        </v-col>

        <v-col md="2" class="pb-2">
          <v-text-field
            dense
            filled
            label="Taken Leave"
            v-model.trim="type.taken"
            solo
            flat
            readonly
            type="number"
            min="0"
            v-on:keyup="addminvalue(type)"
            max="999"
            color="cyan"
          ></v-text-field>
        </v-col>

        <v-col md="2" class="pb-2">
          <v-text-field
            dense
            filled
            label="Balance Leave"
            v-model.trim="type.balance"
            solo
            flat
            readonly
            type="number"
            min="0"
            v-on:keyup="addminvalue(type)"
            max="999"
            color="cyan"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right" md="11">
          <v-btn
            :disabled="!formValid"
            :loading="formLoading"
            v-on:click="onSubmit"
            class="mx-2 custom-grey-border custom-bold-button text-white justify-end"
            color="cyan"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
//import ValidationMixin from "@/core/plugins/validation-mixin";
import { GET, PUT } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
//import { SET_MESSAGE } from "@/core/services/store/common.module";
//import {map} from "lodash";

export default {
  props: {
    engineerId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      maxlengthValid: false,
      // leaveList: [],
      UserList: [],
      formValid: true,
      exitsDays: 0,
      KeyIndays: 0,
      formLoading: false,
      updateDays: 0,
      leaveList: {
        categories: [
          {
            category: null,
            maxdays: 0,
            allocated_days: 0,
          },
        ],
      },
    };
  },
  /*  watch: {
    leaveList(param) {
           console.log(param, "param");
            
    },
  },  */
  methods: {
    getLeaveDays() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "setting/leave",
        })
        .then(({ data }) => {
          _this.leaveList = data;
          _this.getEngineer();
          //  console.log(_this.leaveList,"_this.leaveList");
          /* _this.leaveList = map(data, (row)=>{
         
            return {
              cat_maxdays:row.maxdays,
              category:row.category,
             
            }
          }); */
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    addminvalue(data) {
      //   console.log(data,"data");
      this.exitsDays = data.maxdays;
      this.KeyIndays = data.allocated_days;
      // console.log(Number(this.exitsDays),"this.exitsDays");
      // console.log(this.KeyIndays,"this.KeyIndays");
      if (Number(this.exitsDays) < Number(this.KeyIndays)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(`maxdays less then value `)
        );
        this.formValid = false;
        this.maxlengthValid = false;
      } else {
        this.maxlengthValid = true;
        this.formValid = true;
      }
    },

    /* addminData(data)
      {
      this.exitsDays = data.maxdays
      this.KeyIndays = data.allocated_days
      if(this.exitsDays < this.KeyIndays){
         this.formValid = false;
        
      }
        
      }, */

    getEngineer() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "engineer-leave-get/" + _this.engineerId,
        })
        .then(({ data }) => {
          //console.log(typeof(data.categories));
          if (data.categories) {
            console.log(data.categories);
            this.leaveList = data.categories;
          }

          // console.log(typeof(this.leaveList));
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    /*   datavalidation()
    {
      if(this.cat_maxdays > this.maxdays ){

      }
    },  */

    onSubmit() {
      //alert(54);
      const _this = this;
      /*  if (!_this.$refs.passwordForm.validate()) {
        return false;
      } */

      /* if (!_this.maxlengthValid) {
        return false;
      } */
      _this.formLoading = true;
      console.log(this.leaveList, "leaveList");
      _this.$store
        .dispatch(PUT, {
          url: "leave-update/" + _this.engineerId,
          data: { categories: this.leaveList },
        })
        .then(() => {
          _this.updateDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    // _this.getEngineer();
    _this.getLeaveDays();
  },
};
</script>
